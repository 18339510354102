//import fr from './fr.json'
import auth from './auth.json'
import home from './home.json'

import businessf from './businessf.json'
import search from './search.json'
import Order from './Order.json'
import myOrders from './myOrders.json'
import network from './network.json'
import about from './about.json'

import verification from './verification.json'
import passrecover from './passrecover.json'
import welcome from './welcome.json'
import dashboard from './dashboard.json'
import profileowner from './profileowner.json'


import memnetwork from './memnetwork.json'

import profilefollower from './profilefollower.json'
import businessowner from './businessowner.json'

import settings from './settings.json'



import general from './general.json'


export const defaultLocale = 'fr'

export const fr = {


    auth: auth,
    home: home,
    businessf: businessf,
    search: search,
    Order: Order,
    myOrders: myOrders,
    general: general,

    passrecover: passrecover,

    welcome: welcome,
    dashboard: dashboard,
    profileowner: profileowner,
    memnetwork: memnetwork,
    verification: verification,
    businessowner: businessowner,
    profilefollower: profilefollower,


    network: network,
    about: about,
    settings: settings
    

}